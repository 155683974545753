<template>
    <div class="pvhFactory">
        <basic-container>
            <!--data 数据源 v-model：对象行 -->
            <avue-crud :data="data" v-model="obj" :option="setData.option" :page.sync="page"
                       @current-change="currentChange">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20">
                        <!--查询框-->
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                            <el-input v-model.trim="standardCode" placeholder="请输入标准类型" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <el-input v-model.trim="purchaseNo" placeholder="请输入采购订单号" clearable size="small"
                                      style="width:200px !important;marginRight: 5px;"></el-input>
                            <!--按钮-->
                            <el-button type="primary" icon="el-icon-plus" size="mini" :title="$t('New')" plain
                                       @click="addInfo"> {{$t('New')}}
                            </el-button>
                            <el-button :title="$t('Refresh')" type="primary" icon="el-icon-search" size="mini" plain
                                       @click="refreshChange"> {{$t('Refresh')}}
                            </el-button>
                            <importBtn
                                    :outApi="this.proApis.PROCESSEXPORT"
                                    :innerAPI="this.proApis.PROCESSIMPORT"
                                    :API="this.proApis.PROCESSIMPORTTEMPLATE"
                                    @refresh="refreshData"
                            ></importBtn>
                        </el-col>

                    </el-row>
                </template>
                <template slot-scope="{row,index}" slot="menu">
                    <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                               @click="updateInfo(row,index)"></el-button>
                    <!--          <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')" @click="handleDel(row,index)"></el-button>-->
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
    import {list, remove} from "@/api/materialFeedback";
    import {feedbacklist, standardCodelist} from "@/api/canonicalParameter";
    import importBtn from "@/components/importExport/importBtn";

    export default {
        components:{
            importBtn
        },
        data() {
            return {
                standardCodeData: [],
                feedbackData: [],
                standardCode: "",
                purchaseNo: "",
                obj: {},
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: false,
                    pageSize: 10
                },
                dic: [
                    {
                        label: '符合',
                        value: 0
                    }, {
                        label: '不符合',
                        value: 1
                    }
                ],
                data: [],
            }
        },
        computed: {
            setData() {
                return {
                    option: {
                        viewBtn: false,
                        page: true,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        editBtn: false,
                        addBtn: false,
                        // editBtn: false,
                        refreshBtn: false,
                        labelPosition: 'top',
                        menuAlign: 'center',
                        delBtn: false,
                        column: [
                            {
                                label: '样本大小',
                                prop: "sampleSize",
                            },
                            {
                                label: '产品名称',
                                prop: "productName",
                            },
                            {
                                label: '物料清单编号',
                                prop: "bomNo",
                            },
                            {
                                label: '采购订单号',
                                prop: "purchaseNo",
                            },
                            {
                                label: '颜色',
                                prop: "colour",
                            },
                            {
                                label: '合格范围',
                                prop: "realityMaterial",
                                hide: true
                            },
                            {
                                label: '标准类型',
                                prop: 'standardCode',
                                type: "select",
                                props: {
                                    label: 'code',
                                    value: 'code'
                                },
                                dicData: this.standardCodeData,
                            },
                            {
                                label: '参数编号',
                                prop: 'parameterCode',
                                type: "select",
                                hide: true,
                                props: {
                                    label: 'code',
                                    value: 'code'
                                },
                                dicData: this.feedbackData,
                            },
                            {
                                label: '检验值',
                                prop: "inspectionMaterial",
                                hide: true,
                            },
                            {
                                label: '是否符合标准',
                                prop: "complianceWithStandards",
                                type: "radio",
                                hide: true,
                                dicData: this.dic,
                                mock: {
                                    type: 'dic',
                                },
                            },
                            {
                                label: '评价',
                                prop: "evaluate",
                                hide: true,
                            },
                        ]
                    }
                }
            }
        },
        created() {
            const xx = {
                pageNo: 1,
                pageSize: 100,
                total: 100,
            }
            //code
            standardCodelist(xx).then(res => {
                console.log(66, res);
                this.standardCodeData = res.data.data.items;
            });
            feedbacklist(xx).then(res => {
                this.feedbackData = res.data.data.items;
            })
            this.getList()
        },
        methods: {
            refreshChange() {
                this.getList()
            },
            //新增
            addInfo() {
                this.$router.push({path: '/quality/materialFeedbackAdd', query: {sign: 'add', ID: ''}})
            },
            //修改
            updateInfo(row) {
                this.$router.push({
                    path: '/quality/materialFeedbackAdd',
                    query: {sign: 'update', purchaseNo: row.purchaseNo}
                })
            },
            // 删除
            handleDel(row) {
                this.$confirm("确定要删除吗", "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    remove(row.id).then((response) => {
                        this.getList()
                        this.$message.success(response.data.msg)
                    })
                })
            },
            //导出
            refreshData() {
                this.list();
            },
            getList() {
                const xx = {
                    pageNo: this.page.currentPage,
                    pageSize: this.page.pageSize,
                    total: this.page.total,
                    standardCode: this.standardCode,
                }
                list(xx).then(res => {
                    if (res.data.data == null) {
                        this.data = [];
                        this.page.total = 0;
                    } else {
                        // debugger
                        this.page.total = res.data.data.total
                        this.data = res.data.data.items
                    }
                    console.log(res, 'res')
                }).catch(erro => {
                    console.log(erro);
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList()
            }
        }
    }
</script>
<style scoped>
</style>
